<template>
  <div>
    <el-collapse v-model="filter" @change="handleChange">
      <el-collapse-item title="Фильтр" name="p1">
        <el-row class="m-0 filter">
          <el-col :span="8" class="ml-2 filter--container">
            <span class="mr-2" style="min-width:8rem;">Наименование:</span>
            <el-input v-model="filterName"></el-input>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
    <div>
      <el-row :gutter="10" type="flex" justify="center" style="margin-top:0px">
        <el-col :span="20">
          <div class="text-left">
            <el-button @click="handleCreate" type="text" icon="el-icon-circle-plus-outline">Добавить</el-button>
          </div>
        </el-col>
        <el-col :span="20">
          <div class="text-right">
            <el-button @click="handleDBMetaOpen" type="text" icon="el-icon-document">Метаописание БД</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" style="margin-top:0px">
        <el-col :span="30">
          <el-table v-loading="loading"
                    :height="tableHeight"
                    :data="gridItemsFiltered"
                    :default-sort="{prop: 'updateDate', order: 'descending'}"
                    style="width: 100%">
            <el-table-column label="" width="50">
              <template slot-scope="scope">
                <!-- <el-tooltip content="Редактировать" placement="bottom" effect="light"> -->
                <el-button type="text" icon="el-icon-edit"
                           @click="handleEdit(scope.row)"></el-button>
                <!-- </el-tooltip> -->
              </template>
            </el-table-column><el-table-column label="" width="50">
              <template slot-scope="scope">
                <el-button type="text" icon="el-icon-document-copy"
                           @click="handleCopy(scope.row)"></el-button>
              </template>
            </el-table-column>
            <el-table-column prop="name"
                             label="Название" />
            <el-table-column label="Доступ" width="250" v-if="userHasRoleGroup('LevelIa', 'Admin')">
              <template slot-scope="scope">
                <span>{{ scope.row.roleGroupName ? scope.row.roleGroupName : 'Доступен всем' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Опубликован" width="150" v-if="userHasRoleGroup('LevelIa', 'Admin')">
              <template slot-scope="scope">
                <span>{{ scope.row.published ? 'да' : 'нет' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="updateDate"
                             label="Последнее изменение" width="350">
              <template slot-scope="scope">
                <span>{{  toUserDateV2(scope.row.updateDate,'DD.MM.YYYY HH:mm') }} пользователем {{ scope.row.updateUserName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="createUserName" width="200"
                             label="Автор отчета" />
            <el-table-column label="" width="50">
              <template slot-scope="scope">
                <el-button type="text" icon="el-icon-delete"
                           @click="handleDelete(scope.row)"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-dialog :visible.sync="dbMetaModalVisible" title="Метаописание БД" width="35%" :close-on-click-modal="false">
        <el-row :gutter="20" type="flex" align="middle" justify="center" style="margin-top:0px;">
          <el-col :span="10">
            Существующие метаописания
          </el-col>
          <el-col :span="12">
            <el-button type="text" icon="el-icon-download" @click="handleDBMetaDownload">Скачать</el-button>
            <el-button type="text" icon="el-icon-download" @click="handleDBMetaDownloadEmpty">Скачать (пустой)</el-button>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" align="top" justify="center">
          <el-col :span="10" style="padding-top: 6px; min-height: 67px;">
            Загрузка метаописания
          </el-col>
          <el-col :span="12">
            <el-upload action=""
                       ref="dbMetaFile"
                       :multiple="false"
                       :limit="1"
                       :http-request="handleDBMetaUpload"
                       :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">Выбрать файл</el-button>
              <el-button class="ml-2" size="small" type="success" @click="handleDBMetaSave">Загрузить на сервер</el-button>
            </el-upload>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="center">
          <el-col :span="24">
            <div slot="footer" class="text-right">
              <!--<el-button @click="handleDBMetaClose">Закрыть</el-button>-->
            </div>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import io from '@/utilities/fileIO';
  import tableResize from '@/mixins/tableResize.js';
  import reportsApi from '@/api/reports';
  import UserSettingsService from '@/service/userSettingsService';
  import { mapGetters } from 'vuex';
  import dateHelpers from '@/mixins/dateHelpers';

  export default {
    name: 'Reports',
    mixins: [tableResize, dateHelpers],
    data() {
      return {
        loading: false,
        gridItems: [],
        filter: [''],
        dbMetaModalVisible: false,
        dbMetaFile: {},
        tableSizeConstant: 50,
        filterName: null,
      };
    },
    async created() {
      if (UserSettingsService.has('admin_reports_filter_open', this.user.id)) {
        this.filter = ['p1'];
      }
      this.loading = true;
      await this.getData();
      this.tableHeight = this.getTableHeight();
      this.loading = false;
    },
    computed: {
      ...mapGetters('identity', ['user', 'userHasRoleGroup']),
      gridItemsFiltered() {
        if (!this.filterName) {
          return this.gridItems;
        } else {
          return this.gridItems.filter((x) => x.name.toLowerCase().indexOf(this.filterName.toLowerCase()) >= 0);
        }
      }
    },
    methods: {
      handleChange(val) {
        if (val.find((el) => el === 'p1')) {
          UserSettingsService.set('admin_reports_filter_open', true, this.user.id);
        } else {
          UserSettingsService.delete('admin_reports_filter_open', this.user.id);
        }
      },
      // eslint-disable-next-line no-unused-vars
      async getData() {
        const response = await reportsApi.getMany();
        this.gridItems = response.data.items;
      },
      handleCreate() {
        this.$router.push({ name: 'ReportEdit' });
      },
      handleEdit(report) {
        this.$router.push({ name: 'ReportEdit', params: { reportId: report.id } });
      },
      async handleCopy(report) {
        await reportsApi.copy(report.id);
        this.getData();
      },
      handleDelete(report) {
        this.$confirm(`Отчет ${report.name} будет удален. Продолжить?`, 'Подтверждение', {
          confirmButtonText: 'Да',
          cancelButtonText: 'Отмена',
          type: 'warning',
        }).then(async () => {
          await reportsApi.delete(report.id);
          this.getData();
        }).catch(() => { });
      },
      handleDBMetaOpen() {
        this.dbMetaModalVisible = true;
        if (this.$refs.dbMetaFile) {
          this.$refs.dbMetaFile.clearFiles();
        }
      },
      handleDBMetaClose() {
        this.dbMetaModalVisible = false;
      },
      async handleDBMetaDownload() {
        const response = await reportsApi.downloadDbMetaFile();
        io.downloadFile(response.data.content, response.data.contentType, response.data.fileName);
      },
      async handleDBMetaDownloadEmpty() {
        const response = await reportsApi.downloadEmptyDbMetaFile();
        io.downloadFile(response.data.content, response.data.contentType, response.data.fileName);
      },
      async handleDBMetaUpload(file) {
        io.readBytes(file.file, async (bytes) => {
          await reportsApi.saveDBMetaFile(bytes);
          this.$refs.dbMetaFile.clearFiles();
        });
      },
      handleDBMetaSave() {
        if (this.$refs.dbMetaFile.uploadFiles.length > 0) {
          this.$confirm('Файл метаописания будет заменен. Продолжить?', 'Подтверждение', {
            confirmButtonText: 'Да',
            cancelButtonText: 'Отмена',
            type: 'warning',
          }).then(async () => {
            this.$refs.dbMetaFile.submit();
          }).catch(() => { });
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .el-dialog__wrapper.el-dialog__body {
    padding: 20px;
  }
</style>
